/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useMediaQuery } from "react-responsive"

import Header from "./header"
import Footer from "./footer"
import "./normalize.css"

const Layout = ({
  children,
  pageStyle,
  shadow,
  headerBg,
  colorBlock,
  blogBadge,
  isIntercomOnload,
  bgColor,
}) => {
  // loading intercom script after user scroll to improve initial page load speed

  useEffect(() => {
    // isIntercomOnload
    //   ? intercomWidget(100)
    //   : window.addEventListener("scroll", () => intercomWidget(500))
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const layoutStyle = {
    margin: `0 auto`,
    maxWidth: "100%",
    paddingTop: colorBlock ? 0 : isMobile ? 64 : 114,
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "space-between",
    background: bgColor && bgColor,
  }

  return (
    <>
      <Header colorBlock={colorBlock} blogBadge={blogBadge} />
      <div style={layoutStyle}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageStyle: PropTypes.string,
}

export default Layout
